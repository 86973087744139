// import dependency to handle HTTP request to our back end
import axios from 'axios'
import Vuex from 'vuex'
import Vue from 'vue'
import _ from 'lodash';
import createPersistedState from "vuex-persistedstate";

//load Vuex
Vue.use(Vuex);

//to handle state
const state = {
    terminalData: []
}

//to handle state
const getters = {
    promotedFoundation: function (state) {
        return _(state.terminalData.foundationCategories)
            .thru(function (coll) {
                return _.union(coll, _.map(coll, 'foundations') || []);
            })
            .flatten()
            .find({ isPromoted: true });
    },
    getCategoryById: (state) => (id) => {
        return state.terminalData.foundationCategories.find(item => item.id === parseInt(id))
    },
    getFoundationById: (state) => (id) => {
        var items = state.terminalData.foundationCategories
        var filtered = [];

        _.union(_.forEach(items, function (item) {
            filtered.push(item.foundations.find(item => item.id === parseInt(id))) 
        }))
        return _.spread(_.merge)(_.compact(filtered));
    }
}

//to handle actions
const actions = {
    async getTerminalData({ commit }) {
        return axios.get(process.env.VUE_APP_API_URL + 'v1/Terminal')
            .then(response => {
                commit('SET_DATA', JSON.parse(JSON.stringify(response.data)))
        })
    }
}

//to handle mutations
const mutations = {
    SET_DATA(state, data) {
        state.terminalData = data
    }
}

//export store module
export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
    plugins: [createPersistedState()]
})