<template>
  <div class="h100" v-if="terminalData">
    <img
      id="banner"
      :src="$store.getters.promotedFoundation.primaryBannerPath"
      alt=""
      v-if="$store.getters.promotedFoundation != null"
    />

    <router-link
      id="pay-btn"
      :to="{
        name: 'Payment',
        params: { foundationId: $store.getters.promotedFoundation.id },
      }"
    >
      <img :src="require('@/assets/img/pay-btn.png')" alt="" />
    </router-link>

    <div class="categories">
      <div
        v-for="(item, index) in terminalData.foundationCategories"
        :key="index"
      >
        <router-link
          :to="{ name: 'Foundations', params: { categoryId: item.id } }"
          v-if="item.foundations.length > 1"
        >
          <div
            class="category-item"
            :style="{ 'background-image': 'url(' + item.logoImagePath + ')' }"
          >
            <div class="category-name">
              {{ item.name }}
            </div>
          </div>
        </router-link>
        <router-link
          :to="{
            name: 'Payment',
            params: { foundationId: item.foundations[0].id },
          }"
          v-if="item.foundations.length == 1"
        >
          <div
            class="category-item"
            :style="{ 'background-image': 'url(' + item.logoImagePath + ')' }"
          >
            <div class="category-name">
              {{ item.name }}
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="navbar"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    terminalData() {
      return this.$store.state.terminalData;
    },
  },
  components: {},
  methods: {},
};
</script>