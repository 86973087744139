<template>
  <div class="h100">
    <div class="small-header">
      <img :src="data.logoImagePath" alt="" />
    </div>

    <div class="about">
      <p>{{ data.shortDescription }}</p>
      <p v-html="data.htmlDescription"></p>
    </div>

    <router-link
      class="about-paybtn"
      :to="{ name: 'Payment', params: { foundation: JSON.stringify(data) } }"
      >WESPRZYJ NAS</router-link
    >

    <div class="navbar">
      <router-link :to="{ name: 'Home' }"
        ><img :src="require('@/assets/img/home_btn.png')" alt=""
      /></router-link>
    </div>
  </div>
</template>



<script>
export default {
  props: ["foundation"],
  data() {
    return {
      data: this.$store.getters.getFoundationById(
        this.$route.params.foundationId
      ),
    };
  },
  mounted() {
    console.log(this.foundation);
  },
};
</script>
