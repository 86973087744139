<template>
  <div class="h100">
    <div id="loader" v-if="isLoading">
      <pulse-loader :loading="isLoading" color="#c20f36"></pulse-loader>
    </div>
    <Categories v-if="!singleFoundation" />
    <Payment
      v-if="singleFoundation"
      :foundationId="terminalData.foundationCategories[0].foundations[0].id"
    />
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Categories from "./Categories.vue";
import Payment from "./Payment.vue";

export default {
  data() {
    return {
      isLoading: true,
      singleFoundation: false,
    };
  },
  components: {
    PulseLoader,
    Categories,
    Payment,
  },
  computed: {
    terminalData() {
      return this.$store.state.terminalData;
    },
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      await this.$store.dispatch("getTerminalData");

      if (this.terminalData.foundationCategories.length == 1) {
        if (this.terminalData.foundationCategories[0].foundations.length == 1) {
          this.singleFoundation = true;
        }
      }
      this.isLoading = false;
    },
  },
};
</script>