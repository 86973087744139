<template>
  <div id="app-container" class="row align-items-center justify-content-center">
    <div class="terminal-bg">
      <section id="terminal">
        <router-view />
      </section>
    </div>
    <div class="text">
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')"
        /></a>
      </h2>
      <p>Kościół Mosty funkcjonuje dzięki hojności osób prywatnych.</p>
      <p>
        Jesteśmy chrześcijańskim kościołem, który dzieli się przesłaniem
        Ewangelii dla ludzi w każdym wieku.
      </p>
      <p>
        Aby ten cel realizować, wynajmujemy pomieszczenia dla organizacji
        niedzielnych spotkań, konferencji, szkoleń, zajęć z dziećmi.
      </p>
      <p>
        Otrzymywane wsparcie finansowe, przeznaczamy również na pomoc
        charytatywną ludziom bezdomnym oraz znajdującym się w trudnej sytuacji
        życiowej.
      </p>
      <p>
        Wspieramy także akcje pomocowe, jak min.: Szlachetna Paczka, Pomoc dla
        Ukrainy.
      </p>
      <img :src="require('@/assets/img/logo.png')" alt="" />
    </div>
    <div class="mobile-text" v-if="showText">
      <div><img :src="require('@/assets/img/logo.png')" alt="" /></div>
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')"
        /></a>
      </h2>
      <p>Kościół Mosty funkcjonuje dzięki hojności osób prywatnych.</p>
      <p>
        Jesteśmy chrześcijańskim kościołem, który dzieli się przesłaniem
        Ewangelii dla ludzi w każdym wieku.
      </p>
      <p>
        Aby ten cel realizować, wynajmujemy pomieszczenia dla organizacji
        niedzielnych spotkań, konferencji, szkoleń, zajęć z dziećmi.
      </p>
      <p>
        Otrzymywane wsparcie finansowe, przeznaczamy również na pomoc
        charytatywną ludziom bezdomnym oraz znajdującym się w trudnej sytuacji
        życiowej.
      </p>
      <p>
        Wspieramy także akcje pomocowe, jak min.: Szlachetna Paczka, Pomoc dla
        Ukrainy.
      </p>
    </div>
    <div class="ibtn" v-on:click="showText = !showText">
      <img :src="require('@/assets/img/i_btn.png')" alt="" v-if="!showText" />
      <img :src="require('@/assets/img/x_btn.png')" alt="" v-if="showText" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showText: false,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/styles/App.css";
</style>
