<template>
  <div class="h100">
    <img
      :src="data.logoImagePath"
      alt=""
      style="max-width: 70%; margin-top: 70px; max-height: 150px"
    />

    <h2 style="margin-top: 70px; font-size: 30px">Płatność nieudana</h2>

    <router-link
      class="about-paybtn"
      style="margin-top: 70px"
      :to="{ name: 'Home' }"
      >ZAKOŃCZ</router-link
    >

    <div class="navbar">
      <router-link :to="{ name: 'Home' }"
        ><img :src="require('@/assets/img/home_btn.png')" alt=""
      /></router-link>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      data: this.$store.getters.getFoundationById(
        this.$route.params.foundationId
      ),
    };
  },
};
</script>