<template>
  <div class="h100">
    <div class="small-header">
      <img :src="data.logoImagePath" alt="" />
      <router-link
        :to="{
          name: 'Description',
          params: { foundationId: JSON.stringify(data.id) },
        }"
        ><a>O NAS</a></router-link
      >
    </div>
    <h2>Wybierz <span>kwotę wsparcia</span></h2>

    <div class="payments">
      <a :href="generatePaymentLink(prices[0])">
        <div>
          {{ prices[0] }}
        </div>
      </a>

      <div class="promoted">
        <p>Najczęściej wybierana kwota</p>
        <a :href="generatePaymentLink(prices[1])">
          <div>
            {{ prices[1] }}
          </div>
        </a>
      </div>

      <a :href="generatePaymentLink(prices[2])">
        <div>
          {{ prices[2] }}
        </div>
      </a>

      <div
        style="display: flex; font-size: 18px; align-items: baseline"
        v-if="showInput"
      >
        <imask-input
          v-model="customValue"
          radix="."
          :mask="mask"
          thousandsSeparator=" "
          ref="customInput"
          id="customValue"
          placeholder="Podaj własną kwotę"
        />
        zł
      </div>

      <a href="#" v-if="!showInput" v-on:click="showCustomInput()">
        <div>
          <span>inna kwota</span>
          <span v-if="showInput" @click="customPay()">zapłać</span>
        </div>
      </a>

      <a href="#" v-if="showInput" @click="customPay()">
        <div>
          <span>Zapłać</span>
        </div>
      </a>
    </div>

    <div class="navbar">
      <router-link :to="{ name: 'Home' }"
        ><img :src="require('@/assets/img/home_btn.png')" alt=""
      /></router-link>
    </div>
  </div>
</template>

<script>
import md5 from "md5";
import { IMaskComponent } from "vue-imask";

export default {
  data() {
    return {
      data: this.$store.getters.getFoundationById(this.foundationId),
      prices: JSON.parse(this.$store.state.terminalData.pricesJson),
      showInput: false,
      customValue: null,
      mask: Number,
      currentUrl: "https://" + location.host.toString(),
      host: location.host,
    };
  },
  props: ["foundationId"],
  components: {
    "imask-input": IMaskComponent,
  },
  methods: {
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    generatePaymentLink(amount) {
      let domain = this.currentUrl;
      let tpay_url = "https://secure.tpay.com/?kwota=";
      //let amount = 15;
      let desc = "&opis=Darowizna";
      let error_url = "&pow_url_blad=" + domain + "/error/" + this.data.id;
      let success_url = "&pow_url=" + domain + "/success/" + this.data.id;
      let lang = "&jezyk=PL";
      let id = "&id=" + this.data.tpayId;
      let result_url =
        "&result_url=" +
        process.env.VUE_APP_API_URL +
        "v1/Transaction/" +
        this.$store.state.terminalData.id +
        "/" +
        this.data.id;
      let crc = "&crc=100";
      let md5s = md5(
        this.data.tpayId + "&" + amount + "&100&" + this.data.tpaySecurityCode
      );
      let md5sum = "&md5sum=" + md5s;
      let link =
        tpay_url +
        amount +
        desc +
        error_url +
        success_url +
        result_url +
        crc +
        lang +
        id +
        md5sum;
      return link;
    },
    showCustomInput() {
      this.showInput = !this.showInput;
    },
    customPay() {
      let customPrice = parseFloat(this.customValue.replace(" ", ""));
      console.log(customPrice);

      if (customPrice >= 1 && customPrice < 9999) {
        window.location.href = this.generatePaymentLink(customPrice);
      } else if (customPrice > 9999) {
        alert("Kwota nie może przekraczać 9999 zł.");
      } else {
        alert("Kwota musi być większa niż 1 zł.");
      }
    },
  },
};
</script>