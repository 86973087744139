import Vue from 'vue'
import VueRouter from 'vue-router'
import MainContent from '../components/MainContent.vue'
import Foundations from '../components/Foundations.vue'
import Payment from '../components/Payment.vue'
import Description from '../components/Description.vue'
import Success from '../components/Success.vue'
import Error from '../components/Error.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: MainContent,
  },
  { path: '/foundations/:categoryId', name: 'Foundations', component: Foundations },
  { path: '/payment/:foundationId', name: 'Payment', component: Payment, props: true },
  { path: '/description/:foundationId', name: 'Description', component: Description },
  { path: '/error/:foundationId', name: 'Error', component: Error },
  { path: '/success/:foundationId', name: 'Success', component: Success }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
