import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router"; 
import Hotjar from 'vue-hotjar';
import store from './store';

Vue.use (Hotjar, {
  id: '2802830' // Hotjar Site ID
})

Vue.config.devtools = true;

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");