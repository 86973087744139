<template>
  <div class="h100">
    <h3>{{ data.name }}</h3>

    <p style="margin: 20px">Wybierz fundację</p>

    <div class="categories" v-if="data != null">
      <router-link
        v-for="(item, index) in data.foundations"
        :key="index"
        :to="{ name: 'Payment', params: { foundationId: item.id } }"
      >
        <div class="category-item foundation">
          <img :src="item.logoImagePath" />
          <div class="category-name">
            {{ item.name }}
          </div>
        </div>
      </router-link>
    </div>

    <div class="navbar">
      <router-link :to="{ name: 'Home' }"
        ><img :src="require('@/assets/img/home_btn.png')" alt=""
      /></router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: this.$store.getters.getCategoryById(this.$route.params.categoryId),
    };
  },
  mounted() {
    console.log(this.data);
  },
};
</script>