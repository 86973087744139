<template style="main">
  <Terminal />
</template>

<script>
import Terminal from "./Terminal.vue";

export default {
  name: "MainContent",
  components: {
    Terminal,
  },
  data() {
    return {
      email: null,
    };
  },
};
</script>

<style>
@import "../assets/styles/Mainpage.css";
</style>